import './Toaster.scss'

interface ToasterProps {
    messageLevel: 'INFO' | 'ERROR' | 'WARNING' | 'SUCCESS'
    message: string
    close: () => void
}

const Toaster = (props: ToasterProps) => {
    return <div className='Toaster_wrapper'>
        <div className='Toaster_container'>
            <div className={'Toaster_line ' + props.messageLevel}></div>
            <div className='Toaster_body'>
                <span className='material-symbols-outlined Toaster_body-close' onClick={() => props.close()}>close</span>
                <span>{props.message}</span>
            </div>
        </div>
    </div>
}

export default Toaster