import TextCard from '../../Components/TextCard/TextCard'
import Title from '../../Components/Title/Title'
import './Witnesses.scss'

const Witnesses = () => {     
    // const weddingCouple = {
    //     backgroundImage: `url('./images/sixtine_et_thibault.jpg')`,
    // };
   
    return <div className='Witnesses_wrapper'>
        <Title value='Nos Témoins' />
        <div className='Witnesses_Wedding-image'></div>
        <span className='Witnesses_Text'>Une équipe de choc au rendez-vous !</span>
        <span className='Witnesses_Text'>Depuis un an, ils sont présents pour nous aider à faire de ce mariage le plus beau jour de notre vie : les témoins !</span>
        <span className='Witnesses_Text'>Choisis (presque au hasard), ils sont nos plus chers amis et famille qui peuvent témoigner de notre engagement.</span>
        <span className='Witnesses_Text'>Mais quels seront leurs rôles pendant le mariage ?</span>
        {/* Louis Bourdeau */}
        <TextCard title={'Louis Bourdeau, dit "le tailleur de pierre"'}
                  value={'Ou marteau de guerre ? Entre prouesse sur la pierre, ou batailles légendaires, il saura vous narrer les aventures avec son cousin qu\'ils mènent depuis leurs prime jeunesse !'} 
                  imageDisposition={'right'}
                  imagePath={'./images/witnesses/louis_bourdeau.jpg'}
                  altText={'Louis Bourdeau'}/>
        {/* Alice */}
        <TextCard title={'Alice Buaud Lesur, dite "le bonbon rose"'}
                  value={'Soeur de la mariée, pourra-t-elle la cacher ? Elle fera diversion pour toutes les questions douteuses adressées aux mariés en répondant à la suivante : "alors c\'est pour quand ?"'} 
                  imageDisposition={'left'}
                  imagePath={'./images/witnesses/alice.jpg'}
                  altText={'Alice Buaud Lesur'}/>
        {/* Julien */}
        <TextCard title={'Julien Pons, dit "le combattant "'}
                  value={'Ami du marié, il sort du bocal ! Adepte du parkour, et pour avoir parcouru 5 322 km pour venir au mariage, il s\'adaptera à tous les imprévus !'} 
                  imageDisposition={'right'}
                  imagePath={'./images/witnesses/julien.jpg'}
                  altText={'Julien Pons'}/>
        {/* Clémence */}
        <TextCard title={'Clémence Imbaud dite "la coquette"'}
                  value={'Surnom donné par les grands-parents Jarnier de la mariée...(on se dédouane !) Par ses tenues donc "chics/féminines" elle est à l\'antipode de la mariée, mais vous donnera des anecdotes sur sa petite enfance !'} 
                  imageDisposition={'left'}
                  imagePath={'./images/witnesses/clemence.jpg'}
                  altText={'Clémence Imbaud'}/>
        {/* Jacques */}
        <TextCard title={'Jacques Lesur, dit "le fourbe"'}
                  value={'Frère de la mariée piqué par son futur époux, tout le monde se l\'arrache ! Un as dans l\'art de la fourberie et du déguisement, il n\'hésite pas à faire du zèle à quiconque empêchera les mariés d\'accéder aux petits-fours !'} 
                  imageDisposition={'right'}
                  imagePath={'./images/witnesses/jacques.jpg'}
                  altText={'Jacques Lesur'}/>
        {/* Khanh */}
        <TextCard title={'Khanh Le, dite "la paparazzi"'}
                  value={'Furtive elle sait capter le plus beau profil de la mariée...ou presque ! Besoin d\'une photo souvenir sur votre téléphone ? Elle détournera votre attention grâce à ses talents d\'instagrameuse !'} 
                  imageDisposition={'left'}
                  imagePath={'./images/witnesses/khanh.jpg'}
                  altText={'Khanh Le'}/>
        {/* Pierre */}
        <TextCard title={'Pierre Barabé, dit "le normand "'}
                  value={'Vous connaissez la spécialité du trou normand ? Toujours prêt à trinquer au calvados avec le marié, il vous contera des épopées légendaires sur sa terre natale !'} 
                  imageDisposition={'right'}
                  imagePath={'./images/witnesses/pierre.jpg'}
                  altText={'Pierre Barabé'}/>
        {/* Louis Lesur */}
        <TextCard title={'Louis Lesur, dit "le rossignol "'}
                  value={'Assurancetourix vous dit quelque chose ? Au contraire ! Frère de la mariée, mais surtout connu pour son interprétation légendaire de "l\'air du vent", il vous le fera peindre !'} 
                  imageDisposition={'left'}
                  imagePath={'./images/witnesses/louis_lesur.jpg'}
                  altText={'Louis Lesur'}/>
        {/* Charles */}
        <TextCard title={'Charles Buaud Lesur, dit "le prudent "'}
                  value={'Beau-frère du marié, une entente cordiale ? Oh que non ! Bras dessus, bras dessous, il sera un guide pour éviter toutes les embuscades !'} 
                  imageDisposition={'right'}
                  imagePath={'./images/witnesses/charles.jpg'}
                  altText={'Charles Buaud Lesur'}/>
        {/* Morgan */}
        <TextCard title={'Morgan Delsaux, dit "le café plus "'}
                  value={'Vous êtes fort en jeu de mots ? La mariée a certes le rire facile, mais vous aussi il vous divertira avec ses devinettes... Avez-vous trouvé celle de son surnom ?'} 
                  imageDisposition={'left'}
                  imagePath={'./images/witnesses/morgan.jpg'}
                  altText={'Morgan Delsaux'}/>
        {/* Corentin */}
        <TextCard title={'Corentin Daniel, dit "au sang-froid "'}
                  value={'Un calme irréprochable à toute épreuve ? L\'habit ne fait pas le moine ! Il s\'exaltera au moindre rebondissement ou discours du marié !'} 
                  imageDisposition={'right'}
                  imagePath={'./images/witnesses/corentin.jpg'}
                  altText={'Corentin Daniel'}/>
    </div>
}

export default Witnesses