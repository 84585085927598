import { useState } from 'react'
import Button from '../../../../../../Components/Button/Button'
import { orangeLinearGradient } from '../../../../../../vars'
import { GuestResponse } from '../../../../Guest'
import './SearchModal.scss'

export interface SearchValue {
    lastName: string
    firstName: string
}

interface SearchModalProps {
    searchValues: (value: SearchValue) => void
    close: () => void
    guestResponse: GuestResponse | undefined
    searching: boolean
    backToSearch: () => void
}

const SearchModal = (props: SearchModalProps) => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")


    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            sendSearch(firstName, lastName);
        }
    }

    const sendSearch = (firstName: string, lastName: string) => {
        const values: SearchValue = {lastName, firstName}
        props.searchValues(values)
    }

    const handleClose = () => {
        props.close()
    }
    


    const guest = props.guestResponse
    const commingToAtLeastOneThing = (guest && guest.mass) || 
                                     (guest && guest.cocktail) || 
                                     (guest && guest.dinner) || 
                                     (guest && guest.party)
    const notComming = guest && !guest.mass && 
                       !guest.cocktail && 
                       !guest.dinner && 
                       !guest.party


    

    let guestResponseToString
    let comming
    let howMany
    let mass
    let cocktail
    let dinner
    let party
    if (guest?.firstName === undefined) {
        guestResponseToString = "Vous n'avez pas encore répondu"
    } else {
        if (guest && commingToAtLeastOneThing && guest.guestCount > 1) {
            comming = "Nous venons "
            howMany = "Nous serons " + guest.guestCount + "."
            mass = guest.mass ? "à la messe, " : ""
            cocktail = guest.cocktail ? "au cocktail, " : ""
            dinner = guest.dinner ? "au dîner, " : ""
            party = guest.party ? "et à la soirée" : ""
        } else if (guest && commingToAtLeastOneThing && guest.guestCount < 2) {
            comming = "Je viens "
            howMany = "Je serais seul(e)."
            mass = guest.mass ? "à la messe, " : ""
            cocktail = guest.cocktail ? "au cocktail, " : ""
            dinner = guest.dinner ? "au dîner, " : ""
            party = guest.party ? "et à la soirée" : ""
        } else if (guest && notComming && guest.guestCount > 1) {
            comming = "Nous ne venons pas."
            mass = guest.mass ? "à la messe, " : ""
            cocktail = guest.cocktail ? "au cocktail, " : ""
            dinner = guest.dinner ? "au dîner, " : ""
            party = guest.party ? "et à la soirée" : ""
        } else {
            comming = "Je ne viens pas."
            mass = guest.mass ? "à la messe, " : ""
            cocktail = guest.cocktail ? "au cocktail, " : ""
            dinner = guest.dinner ? "au dîner, " : ""
            party = guest.party ? "et à la soirée" : ""
        }
        const count =  commingToAtLeastOneThing ? howMany : ""

        guestResponseToString = `${guest?.firstName} ${guest?.lastName} : ${count} ${comming} ${mass} ${cocktail} ${dinner} ${party}`
    }

    return <div className='SearchModal_wrapper'>
        <div className='SearchModal_container'>
            <div className='SearchModal_header'>
                <div className='Guest_header-returnButton' onClick={() => handleClose()}>
                    <span className='material-symbols-outlined Input_Button-icon'>{'close'}</span>
                </div>
                <span>Retrouver ma réponse</span>
            </div>
            <div className='SearchModal_body'>
                {props.searching ? <div className='SearchModal_body-search-container'>
                    <div className='SearchModal_body-search-input'>
                        <span>Nom</span>
                        <input placeholder='Nom' value={lastName} onChange={event => setLastName(event.currentTarget.value)} onKeyDown={event => handleKeyDown(event)}></input>
                    </div>
                    <div className='SearchModal_body-search-input'>
                        <span>Prénom</span>
                        <input placeholder='Prénom' value={firstName} onChange={event => setFirstName(event.currentTarget.value)} onKeyDown={event => handleKeyDown(event)}></input>
                    </div>
                </div> : <div className='SearchModal_body-response-container'>
                    <span>{guestResponseToString}</span>
                </div>}
            </div>
            <div className='SearchModal_footer'>
                {!props.searching ? <Button value={'Retour à la recherche'} 
                                               icon={'arrow_back'} 
                                               backgroundColor={orangeLinearGradient} 
                                               color={'white'} 
                                               onClick={() => props.backToSearch()} />
                 : <Button value={'Rechercher'} 
                           icon={'search'} 
                           backgroundColor={orangeLinearGradient} 
                           color={'white'} 
                           onClick={() => sendSearch(firstName, lastName)} />}
            </div>
        </div>
    </div>
}

export default SearchModal