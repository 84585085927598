export const styleVars = {
    white: 'rgb(255, 255, 255)',
    whitePurple: 'rgb(252, 245, 255)',
    purple100: 'rgb(252, 228, 238)',
    purple200: 'rgb(246, 182, 210)',
    purple300: 'rgb(242, 135, 182)',
    purple400: 'rgb(236, 91, 154)',
    purple500: 'rgb(230, 45, 125)',
    purple600: 'rgb(201, 24, 100)',
    purple700: 'rgb(155, 18, 78)',
    purple800: 'rgb(110, 13, 55)',
    purple900: 'rgb(64, 7, 32)',
    orange100: 'rgb(254, 233, 220)',
    orange200: 'rgb(252, 202, 171)',
    orange300: 'rgb(251, 171, 121)',
    orange400: 'rgb(249, 140, 72)',
    orange500: 'rgb(248, 109, 22)',
    orange600: 'rgb(213, 86, 7)',
    orange700: 'rgb(163, 66, 5)',
    orange800: 'rgb(114, 46, 4)',
    orange900: 'rgb(64, 26, 2)',
}

export const roseLinearGradient = `linear-gradient(${styleVars.purple500}, ${styleVars.purple600})`
export const orangeLinearGradient = `linear-gradient(${styleVars.orange500}, ${styleVars.orange600})`
