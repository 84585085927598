import Button from '../../Components/Button/Button'
import Title from '../../Components/Title/Title'
import './Location.css'
import { orangeLinearGradient, styleVars } from '../../vars'

const Location = () => {
    const LocationText = 'Êtes-vous prêt(e) à découvrir un lieu inédit ? '
    const LocationText2 = 'Rendez-vous à l\'issue de la cérémonie religieuse, au château du Deffay, un lieu magnifique !' 
    const LocationText3 = 'Pour nous, cela a été le coup de coeur... Première visite, déjà choisit ! ❤'
    const LocationText4 = 'Entre biches et spatules quoi de mieux qu\'un mariage au milieux de la nature ?'
    const LocationText5 = 'Alors rendez-vous à partir de 17h30 pour respirer un grand bol d\'air frais !'
    
    const handleExternalRedirection = () => window.open('https://www.chateaududeffay.com/', '_blank', 'noreferrer');

    const mapStyle = {
        border: '0'
    }

    var mapWidth = 600
    var mapHeight = 250

    if (window.innerWidth < 800) {
        mapWidth = 250
        mapHeight = 150
    }

    return <div className='Location_wrapper'>
        <div className='Location_text-container'>
            <Title value='Le Lieu' />
            <div className='Location_text-text'>
                <span>{LocationText}</span>
                <span>{LocationText2}</span>
                <span>{LocationText3}</span>
                <span>{LocationText4}</span>
                <span>{LocationText5}</span>
            </div>
            <iframe title='map'
                    src='https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2698.468117372568!2d-2.1595825817202097!3d47.44181374034516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1schateau%20du%20deffay!5e0!3m2!1sfr!2sfr!4v1678734496709!5m2!1sfr!2sfr' 
                    width={mapWidth}
                    height={mapHeight}
                    style={mapStyle} 
                    loading='lazy' 
                    referrerPolicy='no-referrer-when-downgrade'></iframe>
            <Button value='Voir le site du château' 
                    icon='open_in_new' 
                    color={styleVars.white} 
                    backgroundColor={orangeLinearGradient}
                    onClick={handleExternalRedirection}/>
        </div>
        <div className='Location_image-container'></div>
    </div>
}

export default Location