export interface IGuest {
    lastName: string;
    firstName: string;
    mass: boolean;
    cocktail: boolean;
    dinner: boolean;
    party: boolean;
    alreadyAnswered: boolean;
    guestCount: number;
    rowIndex: number;
}

export class Guest implements IGuest {
    static toGuestResponse(guest: Guest): GuestResponse | (() => GuestResponse) {
        return new GuestResponse(guest.lastName, 
            guest.firstName, 
            guest.mass, 
            guest.cocktail, 
            guest.dinner, 
            guest.party, 
            guest.alreadyAnswered, 
            guest.guestCount, 
            '', 
            guest.rowIndex)
    }

    constructor(
      public lastName: string,
      public firstName: string,
      public mass: boolean,
      public cocktail: boolean,
      public dinner: boolean,
      public party: boolean,
      public alreadyAnswered: boolean,
      public guestCount: number,
      public rowIndex: number
    ) {}
}

export interface IGuestResponse {
  lastName: string;
  firstName: string;
  mass: boolean;
  cocktail: boolean;
  dinner: boolean;
  party: boolean;
  alreadyAnswered: boolean;
  guestCount: number;
  commentary: string;
  rowIndex: number;
}

export class GuestResponse implements IGuestResponse {
  constructor(
    public lastName: string,
    public firstName: string,
    public mass: boolean,
    public cocktail: boolean,
    public dinner: boolean,
    public party: boolean,
    public alreadyAnswered: boolean,
    public guestCount: number,
    public commentary: string,
    public rowIndex: number
  ) {}
}
  