import './Button.scss'

interface ButtonProps {
    value: string
    icon: string
    backgroundColor: string
    color: string
    onClick?: () => void
}

const Button = (props: ButtonProps) => {

    const buttonStyle = {
        background: props.backgroundColor,
        color: props.color
    }
    return <div className='Button_wrapper' style={buttonStyle} onClick={props.onClick}>
        <span>{props.value}</span>
        <span className='material-symbols-outlined Button_icon'>{props.icon}</span>
    </div>
}

export default Button