import './Home.css'
import { useNavigate } from 'react-router-dom';
import Button from '../../Components/Button/Button';
import { orangeLinearGradient, styleVars } from '../../vars';

const Home = () => {
    const navigate = useNavigate();
    const handleClick = (destination: any) => navigate(destination)

    return <div className='Home_wrapper'>
            <div className='Home_title-container'>
                <div className='Home_title'>
                    <span>Mariage</span>
                    <span>Sixtine & Thibault</span>
                    <span className='Home_title-text date'>Le 18 Juillet 2023</span>
                </div>
            </div>
            <Button value={'Voir les photos'}
                    onClick={() => handleClick('/photos/1')} 
                    icon='camera' 
                    color={styleVars.white} 
                    backgroundColor={orangeLinearGradient}/>
        </div>
}

export default Home