import './Title.scss'

interface TitleProps {
    value: String
}

const Title = (props: TitleProps) => {
    return <div className='Title'>{props.value}</div>
}

export default Title