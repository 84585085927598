import Button from '../../Components/Button/Button'
import Title from '../../Components/Title/Title'
import './WeddingList.css'
import { orangeLinearGradient, styleVars } from '../../vars'

const WeddingList = () => {
    const weddingListText = 'Non, ce n\'est pas le sommet de la montagne où Thibault a fait sa demande, mais bien notre voyage de noces rêvé !! 🤩'
    const weddingListText2 = 'C\'est la première fois que nous pouvons voyager hors de la France ensemble !'
    const weddingListText3 = 'Faites-nous découvrir l\'Islande, un pays nordique spectaculaire !'
    const weddingListText4 = 'Alors si vous souhaitez participer à notre voyage de noces en Islande, ou nous aider à nous équiper dans notre foyer de jeunes mariés,'
    const weddingListText5 = 'cliquez sur le bouton ci-dessous :'
    

    const handleExternalRedirection = () => window.open('https://www.millemercismariage.com/sixtineetthibault/liste.html', '_blank', 'noreferrer');

    return <div className='WeddingList_wrapper'>
        <div className='WeddingList_image-container'></div>
        <div className='WeddingList_text-container'>
            <Title value='Liste de mariage' />
            <div className='WeddingList_text-text'>
                <span>{weddingListText}</span>
                <span>{weddingListText2}</span>
                <span>{weddingListText3}</span>
                <span>{weddingListText4}</span>
                <span>{weddingListText5}</span>
            </div>
            <Button value='Voir la liste de mariage' 
                    icon='open_in_new' 
                    color={styleVars.white} 
                    backgroundColor={orangeLinearGradient}
                    onClick={handleExternalRedirection}/>
        </div>
    </div>
}

export default WeddingList