import { useEffect, useRef, useState } from 'react'
import './Credits.scss'

const Credits = () => {
    const [infoOpenned, setInfoOpenned] = useState(false)

    const infoRef = useRef<HTMLDivElement>(null)
    const infoButtonRef = useRef<HTMLDivElement>(null)


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if ((infoRef.current && !infoRef.current?.contains(event.target as Node)) 
                    && (infoRef.current && !infoButtonRef.current?.contains(event.target as Node))) {
                        setInfoOpenned(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [infoRef])

    const toggle = () => setInfoOpenned(!infoOpenned)

    return <div className='Credits_wrapper'>
        <div ref={infoButtonRef} className='Credits_button' onClick={toggle}>
            <span className='material-symbols-outlined'>info</span>
        </div>
        {infoOpenned ? <div ref={infoRef} className='Credits_infos'>
            <span>Made with 🍻 by Sixtine & Thibault</span>
            <span><a href='https://www.flaticon.com/fr/icones-gratuites/engagement' title='engagement icônes'>Favicon</a> créée par Freepik - Flaticon</span>
            <span>Photo de <a href='https://unsplash.com/@jonnyauh?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Jonny Auh</a> sur <a href='https://unsplash.com/fr/photos/z99iWocuDt0?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Unsplash</a></span>
            <span>Photo de <a href='https://unsplash.com/@robertbye?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Robert Bye</a> sur <a href='https://unsplash.com/fr/photos/36K5WckeU3o?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Unsplash</a></span>
            <span>Photo de <a href='https://unsplash.com/@joshhild?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Josh Hild</a> sur <a href='https://unsplash.com/fr/s/photos/camping?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Unsplash</a></span>
            <span>Photo de <a href='https://unsplash.com/@thomasschweighofer_?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Thomas Schweighofer</a> sur <a href='https://unsplash.com/fr/s/photos/lord-of-the-ring?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Unsplash</a></span>
            <span>Photo du château du <a href='https://www.chateaududeffay.com/'>Deffay</a></span>
        </div> : <></>}
    </div>
}

export default Credits