import './App.scss'
import Home from './pages/Home/Home'
import WeddingList from './pages/WeddingList/WeddingList'
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './Components/Navigation/Navigation';
import Credits from './Components/Credits/Credits';
import Reply from './pages/Reply/Reply';
import Witnesses from './pages/Witnesses/Witnesses';
import Location from './pages/Location/Location';
import Camping from './pages/Camping/Camping';
import Theme from './pages/Theme/Theme';
import Photos from './pages/Photos/Photos';
import JDR from './pages/JDR/JDR';

const App = () => {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/list' element={<WeddingList />} />
        <Route path='/reply' element={<Reply />} />
        <Route path='/witnesses' element={<Witnesses />} />
        <Route path='/location' element={<Location />} />
        <Route path='/camping' element={<Camping />} />
        <Route path='/theme' element={<Theme />} />
        <Route path='/photos/*' element={<Photos />} />
        <Route path='/JDR' element={<JDR />} />
      </Routes>
      <Credits />
    </Router>
  );
}

export default App
