import './Guest.scss'
import { Guest, GuestResponse } from '../../Guest'
import Button from '../../../../Components/Button/Button';
import { orangeLinearGradient } from '../../../../vars';
import { useState } from 'react';

interface GuestComponentProps {
    guest: Guest
    response: (guest: GuestResponse) => void
    close: () => void
}

const GuestComponent = (props: GuestComponentProps) => {
    const [guest, setGuest] = useState<GuestResponse>(Guest.toGuestResponse(props.guest))
    const initialGuestCount = Number(props.guest.guestCount)

    const handleResponse = () => {
        props.response(guest)
    }

    return <div className='Guest_wrapper'>
        <div className='Guest_header'>
            <div className='Guest_header-returnButton' onClick={() => props.close()}>
                <span className='material-symbols-outlined Input_Button-icon'>{'arrow_back'}</span>
            </div>
            <span>{guest.firstName + " " + guest.lastName}</span>
        </div>
        <div className='Guest_body'>
            <span>Vous êtes invité(e)(s) {props.guest.mass ? "à la messe" : ""} 
                                        {props.guest.cocktail ? ", au cocktail" : ""}
                                        {props.guest.dinner ? ", au dîner" : ""}
                                        {props.guest.party ? ", et à la soirée ! 🎊" : ""}</span>
            <hr style={{width: '100%'}}/>
            <div className='Guest_body-choices'>
                <span>{props.guest.guestCount <= 1 ? "Je viens" : "Nous venons"} :</span>
                {props.guest.mass ? <span className='Guest_body-choices--label' onClick={() => setGuest({...guest, mass: !guest.mass})}><input type="checkbox" checked={guest.mass} onChange={() => setGuest({...guest, mass: !guest.mass})}/>à la messe</span> : <></>}
                {props.guest.cocktail ? <span className='Guest_body-choices--label' onClick={() => setGuest({...guest, cocktail: !guest.cocktail})}><input type="checkbox" checked={guest.cocktail} onChange={() => setGuest({...guest, cocktail: !guest.cocktail})}/>au cocktail</span> : <></>}
                {props.guest.dinner ? <span className='Guest_body-choices--label' onClick={() => setGuest({...guest, dinner: !guest.dinner})}><input type="checkbox" checked={guest.dinner} onChange={() => setGuest({...guest, dinner: !guest.dinner})}/>au dîner</span> : <></>}
                {props.guest.party ? <span className='Guest_body-choices--label' onClick={() => setGuest({...guest, party: !guest.party})}><input type="checkbox" checked={guest.party} onChange={() => setGuest({...guest, party: !guest.party})}/>et à la soirée</span> : <></>}
            </div>
            <div className='Guest_body-count'>
                {props.guest.guestCount > 1 ? <span>{"Nous serons présent(e)s au nombre de : "}
                    <select id="my-select" name="my-select" value={guest.guestCount} onChange={(event) => setGuest({...guest, guestCount: Number(event.currentTarget.value)})}>
                        {Array(initialGuestCount).fill("").map((_, i) => <option key={i+1} value={i+1}>{i+1}</option>)}
                    </select>
                </span> : <></>}
            </div>
            <div className='Guest_body-commentary'>
                <textarea placeholder='Laissez-nous un petit commentaire !' rows={4} cols={40} onChange={(event) => setGuest({...guest, commentary: event.currentTarget.value })}></textarea>
            </div>
            <div className='Guest_body-other'>
                <span>Si vous rencontrez le moindre problème n'hésitez pas à nous envoyer un <a type='mail' href={'mailto:thibaultrobertdev@gmail.com?subject='+ guest.firstName + " " + guest.lastName + " - problème lors de l'envoi de la réponse à l'invitation du mariage" }>mail</a> !</span>
            </div>
        </div>
        <div className='Guest_footer'>
            <Button value={'Envoyer ma réponse'} icon={'send'} backgroundColor={orangeLinearGradient} color={'white'} onClick={() => handleResponse()}/>
        </div>
    </div>
}

export default GuestComponent