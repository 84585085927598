import axios from 'axios';
import backendConfiguration from '../../../src/backend.json';
import './Photos.scss'
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface ImageWithRetryProps {
    imageSource: {path: string, id: string}, 
    maxRetries: number,
    onClick: (image: {path: string, id: string}) => void
}

const ImageWithRetry = (props: ImageWithRetryProps) => {
    const [imageSrc, setImageSrc] = useState(props.imageSource.path);
    const [retryCount, setRetryCount] = useState(0);
  
    const handleImageError = () => {
        if (retryCount < props.maxRetries) {
            setRetryCount(retryCount + 1);
            handleRetry()
        }
    };
  
    const handleRetry = () => {
        setRetryCount(0);
        setImageSrc(props.imageSource.path);
    };
  
    return (
        <>
            {retryCount >= props.maxRetries && (
                <p>Impossible de charger l'image après {props.maxRetries} tentatives.</p>
            )}
            <img src={imageSrc} alt={props.imageSource.id} onError={handleImageError} className='Photos_photo' onClick={() => props.onClick(props.imageSource)} />
        </>
    );
  }
  
interface ImageListWithRetryProps {
    imageSources: {path: string, id: string}[], 
    maxRetries: number,
    onClick: (image: {path: string, id: string}) => void
}

const ImageListWithRetry = (props: ImageListWithRetryProps) => {
    return (
        <div className="Photos_container">
            {props.imageSources.map((photo) => {
                return (
                    <ImageWithRetry
                        key={photo.id}
                        imageSource={photo}
                        maxRetries={props.maxRetries}
                        onClick={image => props.onClick(image)}
                    />
                )}
            )}
        </div>
    );
}

interface ImageFocusProps {
    image: {path: string, id: string}, 
    onClose: () => void;
}

const ImageFocus = (props: ImageFocusProps) => {
    return (
        <div className='Photos_photo--focused-container'>
            <span className='material-symbols-outlined Photos_photo--focused-close-icon' onClick={() => {
                props.onClose()
            }}>close</span>
            <img src={props.image.path} alt={props.image.id} className='Photos_photo--focused-photo'/>
        </div>
    );
}

const Photos = () => {
    const location = useLocation()
    const [data, setData] = useState<string[]>()
    const [dataChunks, setDataChunks] = useState<any[]>()
    const [imageFocused, setImageFocused] = useState<boolean>(false)
    const [image, setImage] = useState<{path: string, id: string}>()
    const [loading, setLoading] = useState(true)
    const [pageIndex, setPageIndex] = useState(0)

    useEffect(() => {
        getPhotos().then((res) => {
            const items = res.filter((photo: any) => photo['fileExtension'] === "jpg")
                            .map((photo: any) => {
                                return {
                                    path: "https://wedding-photos.s3.fr-par.scw.cloud/" + photo['name'],
                                    id: photo['name']
                                }})
                            .sort()
                            .reverse();
            items.unshift(items.pop());
            setData(items);
            setLoading(false);
        })
        setPageIndex(parseInt(location.pathname.split('/').reverse()[0])-1)
    }, [loading, location.pathname]);

    useEffect(() => {
        const dataLength = data ? data?.length : 0;
        const chunkSize = 25;
        if (dataLength > 0) {
            setDataChunks(chunkArrayInGroups(data, chunkSize));
        }
    }, [data]);

    const chunkArrayInGroups = (arr: any, size: number) => {
        var myArray = [];
        for(var i = 0; i < arr.length; i += size) {
          myArray.push(arr.slice(i, i+size));
        }
        return myArray;
    }

    const getPhotos = async () => {
        try {
            const url = `${backendConfiguration.host}/photos`
            return await axios.get(url).then(res => {
                return res.data
            })
        } catch(e) {
            console.log("error while getting photos from backend")
        }
    }

    const setPage = (index: any) => {
        const length = dataChunks ? dataChunks.length : 0;
        if (index >= 0 && index < length) {
            window.scrollTo(0, 0)
            handleNavigation('/photos/'+(index+1))
            setPageIndex(index)
        } else if (index <= 0) {
            window.scrollTo(0, 0)
            handleNavigation('/photos/'+length)
            setPageIndex(length-1)
        } else if (index >= length) {
            window.scrollTo(0, 0)
            handleNavigation('/photos/1')
            setPageIndex(0)
        }
    }

    const navigate = useNavigate()
    const handleNavigation = (destination: any) => {
        navigate(destination)
    }

    return <div className='Photos_wrapper' style={imageFocused ? {overflow: "hidden"} : {}}>
        {imageFocused && image && <ImageFocus image={image} onClose={() => {
                                                                        setImageFocused(false)
                                                                        setImage(undefined)
                                                                    }}/>}
        {loading ? <div className='loader'/> : dataChunks && <ImageListWithRetry 
                                                                imageSources={dataChunks[pageIndex]} 
                                                                maxRetries={3} 
                                                                onClick={image => {
                                                                    setImage(image)
                                                                    setImageFocused(true)
                                                                }}/>}
        {!imageFocused && <div className='Photos_page-manager'>
            <div className='Photos_page-button' onClick={() => setPage(pageIndex-1)}><span className='material-symbols-outlined '>arrow_left</span></div>
            <div>page {pageIndex+1} sur {dataChunks?.length}</div>
            <div className='Photos_page-button' onClick={() => setPage(pageIndex+1)}><span className='material-symbols-outlined '>arrow_right</span></div>
        </div>}
    </div>
}

export default Photos