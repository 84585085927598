import { useState } from "react"
import './Input.scss'

interface InputProps {
    placeholder: string
    buttonBackgroundColor: string
    buttonColor: string
    buttonIcon: string
    onClick: (event: any) => void
    onChange: (value: string) => void
    readResponse: () => void
}

const Input = (props: InputProps) => {
    const [form, setForm] = useState("")

    const handleClick = async (event: any) => {
        return props.onClick(event)
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleClick(event);
        }
    }

    const buttonStyle = {
        background: props.buttonBackgroundColor,
        color: props.buttonColor
    }
    
    return <div className="Input_wrapper">
        <div className="Input_container">
            <input className="Input_input"
                type='text'
                placeholder={props.placeholder}
                value={form}
                onChange={event => {
                    setForm(event.currentTarget.value)
                    props.onChange(event.currentTarget.value)
                }}
                onKeyDown={event => handleKeyDown(event)}>
            </input>
            <div className='Input_button' style={buttonStyle} onClick={handleClick}>
                <span className='material-symbols-outlined Input_Button-icon'>{props.buttonIcon}</span>
            </div>
        </div>
        <div className="Input_link" onClick={() => props.readResponse()}>{'Je souhaite revoir ma réponse'}</div>
    </div>
}

export default Input
