import './GuestSelection.scss'

interface GuestSelectionProps {
    value: string
    onClick: () => void
}

const GuestSelection = (props: GuestSelectionProps) => {
    return <div className='GuestSelection_wrapper' onClick={props.onClick}>
        {props.value}
    </div>
}

export default GuestSelection