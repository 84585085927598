import { useState } from 'react'
import './NavigationButton.scss'
import { styleVars } from '../../../vars'

interface NavigationButtonProps {
    iconRef: string
    text: string
    hoverBackgroundColor?: string
    hoverColor?: string
    isActive?: boolean
    onClick: (event: any) => void
}

const NavigationButton = (props: NavigationButtonProps) => {

    const [isHover, setIsHover] = useState(false);
    
    var background = styleVars.white
    var color = 'black'
    
    if (isHover || props.isActive) {
        background = props.hoverBackgroundColor ? props.hoverBackgroundColor : styleVars.white
        color =  props.hoverColor ? props.hoverColor : 'black'
    }

    const buttonStyle = {
        background,
        color
    }

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    return <div className='NavigationButton_menu-item' 
                style={buttonStyle}
                onMouseEnter={handleMouseEnter} 
                onMouseLeave={handleMouseLeave}
                onClick={(e) => props.onClick(e)}>
        <span className='material-symbols-outlined'>{props.iconRef}</span>
        <span className='NavigationButton_menu-text'>{props.text}</span>
    </div>
}

export default NavigationButton