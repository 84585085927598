import { useState } from 'react';
import './JDR.css'
import { Document, Page } from 'react-pdf'

const JDR = () => {
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber] = useState<number>(1);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <div>
        <Document file="https://drive.google.com/uc?export=view&id=1SIa7uh-xBC_vyafDZGt7DGCGsMigOWIZ" onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
        </Document>
        <p>
            Page {pageNumber} sur {numPages}
        </p>
        </div>
    );
}

export default JDR