import { styleVars } from '../../../../../../vars'
import { GuestResponse } from '../../../../Guest'
import './GuestModal.scss'

interface GuestModalProps {
    guest: GuestResponse | undefined
    confirm: () => void
    cancel: () => void
}

const GuestModal = (props: GuestModalProps) => {
    let comming;
    let howMany;
    const commingToAtLeastOneThing = (props.guest && props.guest.mass) || 
                                     (props.guest && props.guest.cocktail) || 
                                     (props.guest && props.guest.dinner) || 
                                     (props.guest && props.guest.party)
    const notComming = props.guest && !props.guest.mass && 
                       !props.guest.cocktail && 
                       !props.guest.dinner && 
                       !props.guest.party


    if (props.guest && commingToAtLeastOneThing && props.guest.guestCount > 1) {
        comming = "Nous venons "
        howMany = "Nous serons " + props.guest.guestCount + "."
    } else if (props.guest && commingToAtLeastOneThing && props.guest.guestCount < 2) {
        comming = "Je viens "
        howMany = "Je serais seul(e)."
    } else if (props.guest && notComming && props.guest.guestCount > 1) {
        comming = "Nous ne venons pas."
    } else {
        comming = "Je ne viens pas."
    }

    return <div className='GuestModal_wrapper'>
        <div className='GuestModal_container'>
            <div className='GuestModal_header'>
                <span>Récapitulatif de votre réponse</span>
            </div>
            {props.guest ? <div className='GuestModal_body'>
                <span>{props.guest.firstName} {props.guest.lastName}</span>
                <span>{comming}{props.guest.mass ? "à la messe, ": ""} {props.guest.cocktail ? "au cocktail, ": ""} {props.guest.dinner ? "au dîner, ": ""} {props.guest.party ? "et à la soirée.": ""}</span>
                {howMany}
                <span style={{color: styleVars.purple500, paddingTop: '1em'}}>Attention ! Une fois votre réponse envoyée, vous ne pourrez plus la modifier.</span>
            </div> : <></>}
            <div className='GuestModal_footer'>
                <div className='GuestModal_footer-button cancel' onClick={() => props.cancel()}>
                    <span>Annuler l'envoi</span>
                    <span className='material-symbols-outlined'>cancel</span>
                </div>
                <div className='GuestModal_footer-button confirm' onClick={() => props.confirm()}>
                    <span>Confirmer l'envoi</span>
                    <span className='material-symbols-outlined'>check</span>
                </div>
            </div>
        </div>
    </div>
}

export default GuestModal