import './TextCard.scss'

interface TextCardProps {
    title: string
    value: string
    imagePath?: string
    imageDisposition?: 'left' | 'right'
    altText?: string
    linkValue?: string
    linkText?: string
}

const TextCard = (props: TextCardProps) => {
    const imagePath = {
        backgroundImage: `url('${props.imagePath}')`,
    };

    return <div className='TextCard_wrapper'>
        {props.imagePath && props.imageDisposition === 'left' && props.altText ? <div className='TextCard_image-container left'>
            <div className='TextCard_image' style={imagePath}></div>
        </div> : <></>}
        <div className='TextCard_text-container'>
            <span className='TextCard_text title'>{props.title}</span>
            <span className='TextCard_text'>{props.value}</span>
            {props.linkValue && props.linkText ? <div className='TextCard_link-container'>
                <a href={props.linkValue} target='_blank' rel='noreferrer'>{props.linkText}</a>
                <span className='material-symbols-outlined'>open_in_new</span>
            </div> : <></>}
        </div>
        {props.imagePath && props.imageDisposition === 'right' && props.altText ? <div className='TextCard_image-container right'>
            <div className='TextCard_image' style={imagePath}></div>
        </div> : <></>}
    </div>
}

export default TextCard