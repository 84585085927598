import { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import './Navigation.scss'
import { roseLinearGradient, styleVars } from '../../vars';
import NavigationButton from './NavigationButton/NavigationButton'

const Navigation = () => {
    const [menuOpenned, setMenuOpenned] = useState(false)

    const menuRef = useRef<HTMLDivElement>(null)
    const burgerButtonRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if ((menuRef.current && !menuRef.current?.contains(event.target as Node)) 
                    && (menuRef.current && !burgerButtonRef.current?.contains(event.target as Node))) {
                setMenuOpenned(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [menuRef])

    const toggle = () => setMenuOpenned(!menuOpenned)
    
    const navigate = useNavigate()
    const handleNavigation = (destination: any) => {
        navigate(destination)
        setMenuOpenned(false)
    }
    
    const location = useLocation();
    const locationNotHome = location.pathname !== '/'

    const windowSize = useWindowSize()
    const isMobile = windowSize.width < 780

    return <div className='Navigation_wrapper'>
        {!isMobile ? <div className='Navigation_header'>
            <span className='material-symbols-outlined Navigation_header-home' 
                  onClick={() => handleNavigation('/')}>home</span>
            <div className='Navigation_header-buttons'>
                <NavigationButton 
                    iconRef='camera' 
                    text='Photos' 
                    hoverBackgroundColor={roseLinearGradient}
                    hoverColor={styleVars.white}
                    isActive={location.pathname.includes('/photos')}
                    onClick={() => handleNavigation('/photos/1')}/>
                <NavigationButton 
                    iconRef='pin_drop' 
                    text='Le lieu' 
                    hoverBackgroundColor={roseLinearGradient}
                    hoverColor={styleVars.white}
                    isActive={location.pathname === '/location'}
                    onClick={() => handleNavigation('/location')}/>
                <NavigationButton 
                    iconRef='diversity_1' 
                    text='Les témoins' 
                    hoverBackgroundColor={roseLinearGradient}
                    hoverColor={styleVars.white}
                    isActive={location.pathname === '/witnesses'}
                    onClick={() => handleNavigation('/witnesses')}/>
            </div>
        </div> : <></>}

        {isMobile ? <div className={locationNotHome ? 'Navigation_icons-container Navigation_icons-container_dual' :
                        'Navigation_icons-container Navigation_icons-container_mono'}>
            {locationNotHome ? <div className='Navigation_icon' onClick={() => handleNavigation('/')}>
                <span className='material-symbols-outlined'>home</span>
            </div> : <></>}
            {locationNotHome ? <span>|</span> : <></>}
            <div ref={burgerButtonRef} className='Navigation_icon' onClick={toggle}>
                {!menuOpenned ? <span className='material-symbols-outlined'>menu</span> :
                <span className='material-symbols-outlined Navigation_icon_red'>close</span>}
            </div>
        </div>: <></>}
        {menuOpenned ? (
            <div ref={menuRef} className='Navigation_menu-items'>
                <NavigationButton 
                    iconRef='camera' 
                    text='Photos' 
                    hoverBackgroundColor={roseLinearGradient}
                    hoverColor={styleVars.white}
                    isActive={location.pathname === '/photos'}
                    onClick={() => handleNavigation('/photos/1')}/>
                <NavigationButton 
                    iconRef='pin_drop' 
                    text='Le lieu' 
                    hoverBackgroundColor={roseLinearGradient}
                    hoverColor={styleVars.white}
                    isActive={location.pathname === '/location'}
                    onClick={() => handleNavigation('/location')}/>
                <NavigationButton 
                    iconRef='diversity_1' 
                    text='Les témoins' 
                    hoverBackgroundColor={roseLinearGradient}
                    hoverColor={styleVars.white}
                    isActive={location.pathname === '/witnesses'}
                    onClick={() => handleNavigation('/witnesses')}/>
            </div>
        ) : <></>}
    </div>
}

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener('resize', handleResize);

      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

export default Navigation