import Button from '../../Components/Button/Button'
import Title from '../../Components/Title/Title'
import './Theme.css'
import { orangeLinearGradient, styleVars } from '../../vars'

const Theme = () => {
    const ThemeText = 'Et oui ! Nous avons un thème à partir du coktail ! 😉'
    const ThemeText2 = 'Vous connaissez le médiéval fantastique ? C\'est une belle occasion de le découvrir !!'
    const ThemeText3 = 'Vous devez sûrement avoir en tête des univers cinématographiques comme le Seigneur des Anneaux, the Witcher, Game of Thrones...' 
    const ThemeText4 = 'Vous êtes sur la bonne voie !'
    const ThemeText5 = 'Étonnez-nous avec vos costumes médiévaux, de hobbits, d\'elfes, de gobelins, ou toutes autres créatures du genre ! '
    const ThemeText6 = 'Pour les plus récalcitrants, n\'hésitez pas à accessoiriser vos tenues colorées ! '
    const ThemeText7 = 'Prévoyez de vous mettre dans la peau de votre personnage préféré uniquement au milieu du cocktail, une fois les photos officielles prises !'
    const ThemeText8 = 'Les témoins vous donneront le feu vert ! '
    const ThemeText9 = 'Nous ne voudrions pas de trolls pendant la messe ... 🧌'
    const ThemeText10 = ' Merci d\'avance de jouer le jeu ! 🎊'
    const ThemeWebSites = 'Vous pouvez aller sur les sites suivants pour vous dégoter de belles tenues médiévales fantastiques : '
    const comma = ', '

    const handleExternalRedirection = () => window.open('https://www.pinterest.fr/search/pins/?q=medieval%20fantasy%20deguisement&rs=typed', '_blank', 'noreferrer');

    return <div className='Theme_wrapper'>
        <div className='Theme_text-container'>
            <Title value='Le thème' />
            <div className='Theme_text-text'>
                <span>{ThemeText}</span>
                <span>{ThemeText2}</span>
                <span>{ThemeText3}</span>
                <span>{ThemeText4}</span>
                <span>{ThemeText5}</span>
                <span>{ThemeText6}</span>
                <span>{ThemeText7}</span>
                <span>{ThemeText8}</span>
                <span>{ThemeText9}</span>
                <span>{ThemeText10}</span>

                <span>{ThemeWebSites}
                    <a href='https://www.larp-fashion.fr' target='_blank' rel="noreferrer">Larp Fashion</a>{comma}
                    <a href='https://www.boutiquemedievale.fr' target='_blank' rel="noreferrer">La boutique médiévale</a>{comma} 
                    <a href='https://www.etsy.com/fr/market/medieval_costume_fantasy?utm_source=google&utm_medium=cpc&utm_campaign=Search_FR_DSA_GGL_FRE_General-Nonbrand_Catch-All_New&utm_ag=FR-FR_DSA&utm_custom1=_k_CjwKCAjwrdmhBhBBEiwA4Hx5gzpEbYXLu5b0za3L7E33b9gqtW5Ue8hM5goxQCh2obRgAT_W8nVzkhoCBSoQAvD_BwE_k_&utm_content=go_202014800_13813135040_544398565346_aud-1023599305603:dsa-19959388920_c_&utm_custom2=202014800&gclid=CjwKCAjwrdmhBhBBEiwA4Hx5gzpEbYXLu5b0za3L7E33b9gqtW5Ue8hM5goxQCh2obRgAT_W8nVzkhoCBSoQAvD_BwE' target='_blank' rel="noreferrer">Etsy</a>, ...
                </span>
            </div>
            
            <Button value='Voir des idées' 
                    icon='open_in_new' 
                    color={styleVars.white} 
                    backgroundColor={orangeLinearGradient}
                    onClick={handleExternalRedirection}/>
        </div>
        <div className='Theme_image-container'></div>
    </div>
}

export default Theme