import Button from '../../Components/Button/Button'
import Title from '../../Components/Title/Title'
import './Camping.css'
import { orangeLinearGradient, styleVars } from '../../vars'

const Camping = () => {
    const campingText = 'Vous aussi vous souhaitez faire la fête jusqu\'au bout de la nuit ? 😏'
    const campingText2 = 'Mais il y a bien un moment où il faudra songer à dormir !'
    const campingText3 =  '(Pourquoi pas à partir de 3h00 ?)'
    const campingText4 = 'Perfect 👌 il y a un camping juste à côté du château !'
    const campingText5 = 'Prenez un chalet, un mobile home ou un emplacement pour faire de très beaux rêves 🏕'
    
    
    const handleExternalRedirection = () => window.open('https://www.camping-le-deffay.com/', '_blank', 'noreferrer');

    return <div className='Camping_wrapper'>
        <div className='Camping_image-container'></div>
        <div className='Camping_text-container'>
            <Title value='Où dormir ?' />
            <div className='Camping_text-text'>
                <span>{campingText}</span>
                <span>{campingText2}</span>
                <span>{campingText3}</span>
                <span>{campingText4}</span>
                <span>{campingText5}</span>
            </div>
            <Button value='Réserver un emplacement au camping' 
                    icon='open_in_new' 
                    color={styleVars.white} 
                    backgroundColor={orangeLinearGradient}
                    onClick={handleExternalRedirection}/>
        </div>
    </div>
}

export default Camping