import { useState } from 'react'
import axios from 'axios'
import Title from '../../Components/Title/Title'
import { orangeLinearGradient } from '../../vars'
import './Reply.scss'
import backendConfiguration from '../../../src/backend.json';
import { Guest, GuestResponse } from './Guest'
import GuestComponent from './components/Guest/Guest'
import Input from './components/Input/Input'
import GuestSelection from './components/GuestSelection/GuestSelection'
import GuestModal from './components/Guest/components/GuestModal/GuestModal'
import Toaster from '../../Components/Toaster/Toaster'
import SearchModal, { SearchValue } from './components/Input/components/SearchModal/SearchModal'

const Reply = () => {
    const [form, setForm] = useState("")
    const [loading, setLoading] = useState(false)
    const [guests, setGuests] = useState<Guest[]>()
    const [searchResults, setSearchResults] = useState<Guest[]>()
    const [guest, setGuest] = useState<Guest>()
    const [guestReponse, setGuestResponse] = useState<GuestResponse>()
    const [displayModal, setDisplayModal] = useState<boolean>(false)
    const [displaySearchModal, setDisplaySearchModal] = useState<boolean>(false)
    const [displayToaster, setDisplayToaster] = useState<boolean>(false)
    const [noResult, setNoResult] = useState<boolean>(false)
    const [searching, setSearching] = useState<boolean>(true)

    const getGuests = async () => {
        setGuest(undefined)
        setGuests([])
        setLoading(true)
        try {
            setNoResult(false)
            const lastName = form;
            const url = `${backendConfiguration.host}guest/${encodeURIComponent(lastName)}`;

            const response = await axios.get(url)
            const results: Guest[] = response.data
            setGuests(results)
            setSearchResults(results)
            setForm("")
            setLoading(false)
            if (results.length === 0) {
                setNoResult(true)
            }
        } catch(error) {
           setLoading(false) 
        }
    }

    const handleGuestClick = (guest: Guest) => {
        setGuests([])
        setGuest(guest)
    }

    const closeGuestModal = () => {
        setGuests(searchResults)
        setGuest(undefined)
    }

    const sendResponse = async (guest: GuestResponse | undefined) => {
        try {
            setLoading(true)
            const guestReponse = guest ? {...guest, alreadyAnswered: true } : console.log("Error empty guest")
            const lastName = guestReponse ? guestReponse.lastName : "";
            const firstName = guestReponse ? guestReponse.firstName : "";
            const url = `${backendConfiguration.host}response/${encodeURIComponent(lastName)}/${encodeURIComponent(firstName)}`;

            await axios.post(url, guestReponse)
            setGuest(undefined)
            setGuests([])
            setDisplayModal(false)
            setLoading(false)
            setDisplayToaster(true)
            setTimeout(() => setDisplayToaster(false), 10000)
        } catch(error) {
            console.log("error", error)
            setLoading(false)
        }
    }

    const handleConfirmationModal = (guestResponse: GuestResponse) => {
        setGuestResponse(guestResponse)
        setDisplayModal(true)
    }

    const searchResponse = async (value: SearchValue) => {
        setLoading(true)
        const lastName = value.lastName;
        const firstName = value.firstName;
        const url = `${backendConfiguration.host}guest/response/${encodeURIComponent(lastName)}/${encodeURIComponent(firstName)}`;

        const response = await axios.get(url)
        setGuestResponse(response.data[0])
        setLoading(false)
        setSearching(false)
    }

    const isMobile = window.innerWidth < 780

    return <div className='Reply_wrapper'>
        <Title value='Réponses' />
        <span className='Reply-title-text'>Réponse souhaitée avant le 1er Juin</span>
        <span className='Reply-title-text'>Costumé(e)(s) à partir de 18h30</span>
        <div className='Reply_form-container'>
            <Input placeholder={isMobile ? 'Votre nom de famille' : 'Entrez votre nom de famille'} 
                   buttonBackgroundColor={orangeLinearGradient} 
                   buttonColor={'white'} 
                   buttonIcon={'search'}
                   onChange={value => setForm(value)}
                   onClick={getGuests}
                   readResponse={() => setDisplaySearchModal(true)} />
        </div>
        {loading ? <div className="loader"></div> : <></>}
        <div className='Reply_guests-container' style={displayModal ? {overflow: "hidden"} : {}}>
            {guests && !guest ? guests.map(guest => 
                <GuestSelection key={guest.firstName+guest.lastName} 
                                value={guest.firstName + " " + guest.lastName} 
                                onClick={() => handleGuestClick(guest)}/>
            ) : <></>}
            {guest ? <GuestComponent key={guest.firstName+guest.lastName} 
                                     guest={guest} 
                                     response={(guest: GuestResponse) => handleConfirmationModal(guest)}
                                     close={() => closeGuestModal()} /> : 
                     <div>
                        {noResult ? 'Pas de résultat pour cette recherche' : ''}
                     </div>}
        </div>
        {displayModal ? <GuestModal guest={guestReponse} confirm={() => sendResponse(guestReponse)} cancel={() => setDisplayModal(false)} /> : <></>}
        {displaySearchModal ? <SearchModal searchValues={(value: SearchValue) => searchResponse(value)} 
                                           close={() => setDisplaySearchModal(false)} 
                                           guestResponse={guestReponse} 
                                           searching={searching}
                                           backToSearch={() => setSearching(true)}/> : <></>}
        {displayToaster ? <Toaster close={() => setDisplayToaster(false)} messageLevel={'SUCCESS'} message={'Merci ! Votre réponse est bien enregistrée ! N\'hésitez pas à aller voir la page sur le thème pour en savoir plus !'} /> : <></>}
    </div>
}

export default Reply